<template>
  <v-dialog v-model="dialogView" max-width="530px">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item class="apListItem" v-on="on">
        <v-icon
          class="mr-2"
          small
          style="margin-top: 0px !important"
          dark
          icon
          size="18"
          v-bind="attrs"
        >
          $eye
        </v-icon>
        <span> {{ $t("view", { name: $tc("") }) }}</span>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="text-h5">{{
        $t("view", { name: pack.description })
      }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialogView = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="pack.description"
                dense
                readonly
                outlined
                :label="$t('packs.description')"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="pack.price"
                dense
                outlined
                readonly
                :label="$t('packs.price')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-simple-table
            background-color="transparent"
            style="margin-left: 10px"
          >
            <thead>
              <tr>
                <td style="color: var(--v-primary-base); text-align: center">
                  {{ $t("packs.sub_artist_type") }}
                </td>
                <td style="color: var(--v-primary-base); text-align: center">
                  {{ $tc("quantity", 1) }}
                </td>
                <td style="color: var(--v-primary-base); text-align: center">
                  {{ $tc("price", 1) }}
                </td>
                <td
                  style="color: var(--v-primary-base); text-align: center"
                  v-if="!$store.getters['auth/isStudio']"
                >
                  {{ $tc("studio", 1) }}
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sub_artist_type, i) in pack.sub_artist_type" :key="i">
                <td class="pt-0">
                  {{ sub_artist_type.description }}
                </td>
                <td>
                  {{ sub_artist_type.pivot.quantity }}
                </td>
                <td>
                  {{
                    $n(
                      sub_artist_type.price * sub_artist_type.pivot.quantity,
                      "currency"
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ViewPack",
  props: ["pack"],
  data() {
    return {
      dialogView: false,
      sub_artist_types: [],
    };
  },
  methods: {
    ...mapActions("subservices", ["getSubServices"]),
    fetchSubServices() {
      console.log("FETCH");
      this.loading = true;
      this.getSubServices({
        filters: {},
        pagination: { itemsPerPage: -1 },
        service_id: "all",
      }).then((subservices) => {
        this.sub_artist_types = subservices.data;
        console.log(this.sub_artist_types);
      });
    },
  },
};
</script>
